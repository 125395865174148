<template>
  <div id="h3-characters">
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <h3>H3 Characters</h3>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="mb-2">
        <b-row>
          <b-col cols="12" lg="12">
            <b-input-group>
              <b-input-group-append>
                <b-form-input
                  class="form-control"
                  type="text"
                  placeholder="Search"
                  v-model="keyword"
                  @keyup.enter="doFilter"
                ></b-form-input>
                <b-button
                  type="button"
                  title="Search Character"
                  variant="primary"
                  @click="doFilter">
                  <b-icon icon="search" aria-hidden="true"></b-icon>
                </b-button>
              </b-input-group-append>
              <b-button
                class="ml-2"
                type="button"
                title="Reset Search"
                variant="outline-primary"
                @click="resetFilter"
                :disabled="!keyword">
                <b-icon
                  icon="arrow-counterclockwise"
                  aria-hidden="true"
                  scale="1"
                ></b-icon>
              </b-button>
              <b-button
                class="ml-2"
                type="button"
                title="Create New Character"
                variant="outline-success"
                @click="createCharacterModal">
                <b-icon
                  icon="plus"
                  aria-hidden="true"
                  scale="1.5"
                ></b-icon> Character
              </b-button>
            </b-input-group>
          </b-col>
          <b-col cols="12" lg="8"></b-col>
        </b-row>
        
      </div>

      <!-- Character Accounts -->
      <vuetable
        data-path="accounts"
        pagination-path="pagination"
        ref="vuetable"
        track-by="user_id"
        :api-url="apiUrl"
        :fields="fields"
        :http-options="httpOptions"
        :css="css.table"
        :per-page="10"
        :append-params="moreParams"
        :query-params="{
          sort    : 'sort',
          page    : 'page',
          perPage : 'perPage'
        }"
        @vuetable:pagination-data="onPaginationData"
        @vuetable:loading="showLoader"
        @vuetable:loaded="hideLoader">

        <!-- Actions -->
        <template
          class="text-center"
          slot="actions"
          slot-scope="props">
          <b-button
            class="mr-1"
            title="Edit Character"
            type="button"
            variant="success"
            @click="editCharacterModal(props.rowData)">
            <b-icon icon="pencil" aria-hidden="true"></b-icon>
          </b-button>
          <b-button
            title="Delete Character"
            type="button"
            variant="danger"
            @click="deleteCharacter(props.rowData)">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
        </template>

        <template slot="photo" slot-scope="props">
          <img
            class="img-fluid"
            :class="props.rowData.photo ? 'photo' : 'default-photo'"
            :src="props.rowData.photo"
            :alt="`${props.rowData.nickname} Photo`"
            :title="`${props.rowData.nickname} Photo`"
            @error="setAltThumbnail"
          />
        </template>

        <!-- Status -->
        <template slot="status" slot-scope="props">
          <div v-if="props.rowData.is_social == 0">
            <div v-if="props.rowData.status === 1">
              <span v-if="props.rowData.activation === 1"> Activated </span>
              <span v-else> For Activation </span>
            </div>
            <span v-else> Deactivated </span>
          </div>
          <div v-else>
            <span v-if="props.rowData.status == 1"> Activated </span>
            <span v-else> Deactivated </span>
          </div>
        </template>

        <template slot="tier" slot-scope="props">
          <div v-if="props.rowData.tier == 0">Free</div>
          <div v-if="props.rowData.tier == 1">Champion</div>
        </template>
      </vuetable>

      <!-- Pagination -->
      <div class="d-flex align-items-center justify-content-end">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
        <vuetable-pagination
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
          :css="css.pagination">
        </vuetable-pagination>
      </div>
    </div>

    <!-- Modal -->
    <modals-container />

    <!-- Dialog -->
    <v-dialog />

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment-timezone';
  import TableStyle from '../table-bootstrap-css.js';
  import baseHeader from '../../BaseHeader';
  const CreateCharacterModal = () => import('./CreateCharacterModal');
  const EditCharacterModal = () => import('./EditCharacterModal');

  export default {
    head : {
      title : {
        inner      : 'Admin - H3 Characters',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        loading    : false,
        keyword    : null,
        apiUrl     : process.env.VUE_APP_ROOT_API + 'api/users',
        moreParams : {
          userType : 7,
        },
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        css    : TableStyle,
        fields : [ {
          name       : 'user_id',
          title      : 'ID',
          sortField  : 'user_id',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:photo',
          title      : 'Photo',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name      : 'email',
          title     : 'Email',
          sortField : 'email',
          direction : 'desc',
        }, {
          name      : 'nickname',
          title     : 'Nickname',
          sortField : 'nickname',
          direction : 'desc',
        }, {
          name      : 'firstname',
          title     : 'First Name',
          sortField : 'firstname',
          direction : 'desc',
        }, {
          name      : 'lastname',
          title     : 'Last Name',
          sortField : 'lastname',
          direction : 'desc',
        }, {
          name       : '__slot:tier',
          title      : 'Tier',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'created',
          title      : 'Created',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:status',
          title      : 'Status',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:actions',
          title      : 'Actions',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        } ],
      }
    },
    components : {
      'AppLoaderAdmin' :
        () => import('../../../components/layout/AppLoaderAdmin'),
      'Vuetable'           : () => import('vuetable-2/src/components/Vuetable'),
      'VuetablePagination' :
        () => import('vuetable-2/src/components/VuetablePagination'),
      'VuetablePaginationInfo' :
        () => import('vuetable-2/src/components/VuetablePaginationInfo'),
        baseHeader
    },
    watch : {
      keyword(val) {
        if (!val)
          this.resetFilter();
      },
    },
    methods : {

      /**
       * Table Data
       * @param data
       * @return {object}
       */
      transform(data) {
        const transformed = {};
        transformed.accounts = [];

        transformed.pagination = {
          'total'         : data.total,
          'per_page'      : data.perPage,
          'current_page'  : data.currentPage,
          'last_page'     : data.lastPage,
          'next_page_url' : data.nextPageUrl,
          'prev_page_url' : data.prevPageUrl,
          'from'          : data.from,
          'to'            : data.to,
        };

        for (let i = 0; i < data.data.length; i++) {
          const created = moment.utc(data.data[i].created_utc).toDate();
          const createdConv = moment(created).local().format('MM/DD/YYYY');

          // const createdConv = moment(created).local()
          //   .format('MMM DD, YYYY, hh:mm:ss A z');
          transformed.accounts.push({
            'user_id'    : data.data[i].user_id,
            'photo'      : data.data[i].profile_img_url,
            'nickname'   : data.data[i].nickname,
            'firstname'  : data.data[i].firstname,
            'lastname'   : data.data[i].lastname,
            'email'      : data.data[i].email,
            'status'     : data.data[i].status,
            'tier'       : data.data[i].premium_type,
            'activation' : data.data[i].activation_used,
            'is_social'  : data.data[i].is_social,
            'created'    : createdConv,
          });
        }

        return transformed;
      },

      /**
       * Create New H3 Character Account
       */
      createCharacterModal() {
        this.$modal.show(CreateCharacterModal, {}, {
          height   : 'auto',
          adaptive : true,
        }, {
          'reload-table' : event => {
            this.refreshTable();
          },
        });
      },

      /**
       * Edit Character Account
       * @param data
       */
      editCharacterModal(data) {
        this.$modal.show(EditCharacterModal, {
          userData : data,
        }, {
          height   : 'auto',
          adaptive : true,
        }, {
          'reload-table' : event => {
            this.refreshTable();
          },
        });
      },

      /**
       * Delete Character Account
       * @param data
       */
      deleteCharacter(data) {
        this.$modal.show('dialog', {
          title : 'Delete Character',
          text  : `Do you want to delete <b>${data.firstname} ` +
            ` ${data.lastname}</b>?`,
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.showLoader();
              this.$http.delete('api/user', {
                data : {
                  userId : data.user_id,
                },
              }).then(() => {
                this.refreshTable();
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Deleted character successfully.',
                });
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              }).finally(() => {
                this.hideLoader();
              });
            },
          }, {
            title : 'No',
          } ],
        })
      },

      /**
       * Refresh H3 Characters Table
       */
      refreshTable() {
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Do Filter
       */
      doFilter() {
        this.moreParams = {
          userType : 7,
          filter   : this.keyword,
        };
        this.refreshTable();
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.keyword = null;
        this.moreParams = {
          userType : 7,
        };
        this.refreshTable();
      },

      /**
       * Update pagination info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * On Change Page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * hide Loader
       */
      hideLoader() {
        this.loading = false;
      },

      /**
       * Set Alternative Thumbnail
       * @param event
       */
      setAltThumbnail(event) {
        event.target.src =
          require('../../../assets/images/default_profile_photo.png');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .form-control {
    height: auto;
  }

  .default-photo, .photo {
    width: 5rem;
    height: 5rem;
  }

  .default-photo {
    background-color: rgba(0,0,0,0.3);
  }

  .baseHeader, .header {
    margin-top: -80px;
  }

  table {
    z-index: 99;
    position: relative;
    background: white;
  }
</style>