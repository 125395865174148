<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <h3>Moderate Content</h3>
    </base-header>
    <div class="container-fluid mt--7">
      <b-row>
        <b-col xl="9">
          <b-card class="mb-2">
            <b-row>
              <b-col
                :cols="colNum"
                v-for="(issue, index) in issues"
                :key="index">
                <b-card
                  class="mb-4"
                  :img-src="issue.content[0].url"
                  @click="selectCard(issue)"
                  img-top>
                  <b-card-text>
                    <p class="name mb-0">
                      {{ issue.contentOwner[0].firstname }}
                      {{ issue.contentOwner[0].lastname }}
                    </p>
                    <small class="text-muted role mb-0">
                      Owner
                    </small>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
  
            <template #footer>
              <div v-if="issues != ''">
                <b-pagination
                  class="mb-0" align="center"
                  :per-page="issuesPerPage"
                  size="md"
                  :total-rows="issuesTotal"
                  v-model="currentPage">
                </b-pagination>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col xl="3">
          <view-card @update-issues="updateIssues"></view-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  const ViewCard = () => import('./ViewCard.vue');
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import baseHeader from '../../BaseHeader';

  export default {
    name   : 'ModerateContent',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        issuesCurrentPage : 0,
        issuesLastPage    : 0,
        issuesPerPage     : 8,
        issuesTotal       : 0,
        issues            : {},
        currentPage       : 1,
      }
    },
    components : {
      ViewCard,
      baseHeader
    },
    computed : {
      col() {
        return 'col-' + this.getPortion(4);
      },
      colNum() {
        return this.getPortion(4);
      },
    },
    watch : {
      currentPage(value) {
        this.getIssues(value - 1);
      },
    },
    methods : {

      /**
       * Selected Card
       */
      selectCard(issue) {
        this.$router.push({ path : '/admin/moderate-content/' + issue.ucc_id });
      },

      /**
       * Get All Contested Issues
       */
      getIssues(currentPage = this.issuesCurrentPage) {
        this.$http.get('api/issue/admin', {
          params : {
            page    : currentPage + 1,
            perPage : this.issuesPerPage,
          },
        }).then(response => {
          this.issues = response.data.data.issues;
          this.issuesCurrentPage = response.data.data.currentPage;
          this.issuesLastPage = response.data.data.lastPage;
          this.issuesTotal = response.data.data.total;
        });
      },

      updateIssues() {
        this.issuesCurrentPage = 0;
        this.issuesTotal = 0;
        this.issues = {};
        this.currentPage = 1;

        this.getIssues();
      },
    },
    mounted() {
      this.getIssues(0);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .card {
    img {
      height: 15rem;
      object-fit: cover;
    }
  }

  .card-text {
    line-height: 1;
  }

  .name {
    margin-bottom: 0;
    font-size: 0.8em;
    font-weight: bold;
    line-height: 1;
  }
  .role {
    font-size: 0.65em;
    line-height: 1;
  }

  .baseHeader, .header {
    margin-top: -80px;
  }

  table {
    z-index: 99;
    position: relative;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {}

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {}
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {}
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {}
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .card {
      img {
        height: 10rem;
      }
    }
    .card-body {
      padding: 0.75rem;
    }
  }
  @media only screen and (min-width: $special) {
    .card-body {
      padding: 0.75rem;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .card {
      img {
        height: 15rem;
        object-fit: cover;
      }
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}
</style>
