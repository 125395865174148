<template>
  <div id="admin" :class="[{ 'collapsed' : collapsed }, { 'onmobile' : isOnMobile }]">
    <div class="admin">
      <!-- Header -->
      <!-- <div class="admin-header" :class="[collapsed ? 'extended' : 'normal']">
        <b-row>
          <b-col class="my-auto" cols="6">
            <router-link to="/admin">
              <img class="logo" src="../../assets/images/h3_logo.png" alt="Healthy Hip Hop Logo" />
            </router-link>
          </b-col>
          <b-col class="text-right my-auto" cols="6">
            <span class="logout-link" @click="logout">Logout</span>
          </b-col>
        </b-row>
      </div> -->

      <base-nav container-classes="container-fluid" class="navbar-top navbar-expand"
        :class="{ 'navbar-dark': type === 'default' }">
        <a href="#" aria-current="page"
          class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active">
          <!-- {{ $route.name }} -->
        </a>
        <!-- Navbar links -->
        <b-navbar-nav class="align-items-center ml-md-auto">
          <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
          <li class="nav-item d-sm-none">
            <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
              <i class="ni ni-zoom-split-in"></i>
            </a>
          </li>
        </b-navbar-nav>
        <b-navbar-nav class="align-items-center ml-auto ml-md-0">
          <b-form class="navbar-search form-inline mr-sm-3"
            :class="{ 'navbar-search-dark': type === 'default', 'navbar-search-light': type === 'light' }"
            id="navbar-search-main">
            <b-form-group class="mb-0">
              <b-input-group class="input-group-alternative input-group-merge">
                <b-form-input placeholder="Search" type="text"> </b-form-input>

                <div class="input-group-append">
                  <span class="input-group-text"><i class="fas fa-search"></i></span>
                </div>
              </b-input-group>
            </b-form-group>
          </b-form>
          <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
              <b-media no-body class="align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <i class="ni ni-single-02 text-yellow"></i>
                </span>
                <b-media-body class="ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm  font-weight-bold">H3 Admin</span>
                </b-media-body>
              </b-media>
            </a>

            <template>

              <!-- <b-dropdown-header class="noti-title">
                <h6 class="text-overflow m-0">Welcome!</h6>
              </b-dropdown-header>
              <b-dropdown-item href="#!">
                <i class="ni ni-single-02"></i>
                <span>My profile</span>
              </b-dropdown-item>
              <b-dropdown-item href="#!">
                <i class="ni ni-settings-gear-65"></i>
                <span>Settings</span>
              </b-dropdown-item>
              <b-dropdown-item href="#!">
                <i class="ni ni-calendar-grid-58"></i>
                <span>Activity</span>
              </b-dropdown-item>
              <b-dropdown-item href="#!">
                <i class="ni ni-support-16"></i>
                <span>Support</span>
              </b-dropdown-item>
              <div class="dropdown-divider"></div> -->
              <b-dropdown-item>
                <i class="ni ni-user-run"></i>
                <span class="logout-link" @click="logout">Logout</span>
              </b-dropdown-item>

            </template>
          </base-dropdown>
        </b-navbar-nav>
      </base-nav>

      <!-- Container -->
      <div class="admin-container">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>

      <!-- Sidebar Menu -->
      <!-- <sidebar-menu :menu="menu" :collapsed="collapsed" :width="'280px'" :widthCollapsed="'50px'" :showOneChild="true"
        @toggle-collapse="onToggleCollapse">
      </sidebar-menu> -->
      <div class="sidebar-overlay" v-if="isOnMobile && !collapsed" @click="collapsed = true" />
      <side-bar>
        <hr class="my-3">
        <h6 class="navbar-heading p-0 text-muted">Adminitration</h6>
        <template slot="links">
          <sidebar-item v-for="(item, index) in menu" :key="index" :link="{
            name: item.title,
            path: item.href,
            icon: item.icon,
            children: item.child || []  // Asegúrate de incluir los subitems
          }">
            <template v-if="item.child && item.child.length">
              <sidebar-item v-for="(child, childIndex) in item.child" :key="childIndex" :link="{
                name: child.title,
                path: child.href,
              }"></sidebar-item>
            </template>
          </sidebar-item>


          <!-- <sidebar-item :link="{
            name: 'Icons',
            path: '/icons',
            icon: 'ni ni-planet text-blue'
          }">
          </sidebar-item>

          <sidebar-item :link="{
            name: 'Maps',
            path: '/maps',
            icon: 'ni ni-pin-3 text-orange'
          }">
          </sidebar-item>

          <sidebar-item :link="{
            name: 'User Profile',
            path: '/profile',
            icon: 'ni ni-single-02 text-yellow'
          }">
          </sidebar-item>

          <sidebar-item :link="{
            name: 'Tables',
            path: '/tables',
            icon: 'ni ni-bullet-list-67 text-red'
          }">
          </sidebar-item>

          <sidebar-item :link="{
            name: 'Login',
            path: '/login',
            icon: 'ni ni-key-25 text-info'
          }">
          </sidebar-item>
          <sidebar-item :link="{
            name: 'Register',
            path: '/register',
            icon: 'ni ni-circle-08 text-pink'
          }">
          </sidebar-item> -->
        </template>

        <template slot="links-after">
          <hr class="my-3">
          <h6 class="navbar-heading p-0 text-muted">Documentation</h6>

          <b-nav class="navbar-nav mb-md-3">
            <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard">
              <i class="ni ni-spaceship"></i>
              <b-nav-text class="p-0">Getting started</b-nav-text>
            </b-nav-item>
            <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard">
              <i class="ni ni-palette"></i>
              <b-nav-text class="p-0">Foundation</b-nav-text>
            </b-nav-item>
            <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard">
              <i class="ni ni-ui-04"></i>
              <b-nav-text class="p-0">Components</b-nav-text>
            </b-nav-item>
          </b-nav>
        </template>
      </side-bar>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>

  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import ContentFooter from '../ContentFooter.vue';


  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    data() {
      return {
        loading    : false,
        collapsed  : false,
        isOnMobile : false,
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        menu       : [
          // {
          //   header           : true,
          //   title            : 'Administration',
          //   hiddenOnCollapse : true,
          // },
          {
            href  : '/admin/dashboard',
            title : 'Dashboard',
            icon  : 'fa fa-chart-area',
          },
          // {
          //   href  : '/admin/featured',
          //   title : 'Featured',
          //   icon  : 'fas fa-star',
          // },
          // {
          //   href  : '/admin/categories',
          //   title : 'Categories',
          //   icon  : 'fas fa-object-group',
          //   child : [
          //     {
          //       href  : '/admin/categories/interact',
          //       title : 'Interact',
          //     },
          //   ],
          // },
          {
            title : 'Accounts',
            icon  : 'fa fa-users',
            child : [
              {
                href  : '/admin/users',
                title : 'Users',
              },
              {
                href  : '/admin/influencers',
                title : 'Influencers',
              },
              {
                href  : '/admin/enterprise',
                title : 'Enterprise',
              },
              {
                href  : '/admin/h3-characters',
                title : 'H3 Characters',
              },
            ],
          },
          {
            title : 'Albums',
            icon  : 'fas fa-music',
            child : [
              {
                href  : '/admin/music-albums/create',
                title : 'Create Music Album',
              },
              {
                href  : '/admin/music-albums',
                title : 'Music Albums',
              },
              // {
              //   href  : '/admin/challenges-albums/create',
              //   title : 'Create Challenges Album',
              // },
              // {
              //   href  : '/admin/challenges-albums',
              //   title : 'Challenges Albums',
              // },
               {
                href  : '/admin/playlist/1',
                title : 'Playlist',
              },
            ],
          },
          {
            title : 'Videos',
            icon  : 'fas fa-video',
            child : [
              {
                href  : '/admin/videos',
                title : 'Videos'
              },
              {
                href  : '/admin/videos/upload',
                title : 'Upload videos',
              },
            ],
          },
          {
            href  : '/admin/announcements',
            title : 'Announcements',
            icon  : 'fas fa-bullhorn',
          },
          {
            href  : '/admin/live-broadcast',
            title : 'Live Broadcast',
            icon  : 'fas fa-broadcast-tower',
          },
          // {
          //   href  : '/admin/mobile-filter-assets',
          //   title : 'Mobile Filter Assets',
          //   icon  : 'fas fa-theater-masks',
          // },
          {
            href  : '/admin/moderate-content',
            title : 'Moderate Content',
            icon  : 'fas fa-flag',
          },
          // {
          //   href  : '/admin/playlist/1',
          //   title : 'Playlist',
          //   icon  : 'fas fa-stream',
          // },
          {
            href  : '/admin/web-settings',
            title : 'Web Settings',
            icon  : 'fas fa-cog',
            class : 'web-settings',
          },
        ],
      }
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
      'BaseDropdown': () => import('@/components/BaseDropdown'),
      'BaseNav': () => import('@/components/Navbar/BaseNav'),
      'side-bar': () => import('@/components/SidebarPlugin/SideBar'),
      ContentFooter
    },
    props: {
      type: {
        type: String,
        default: 'default', // default|light
        description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
      }
    },
    computed: {
      routeName() {
        const { name } = this.$route; 
        console.log('[9090]', name); // Desestructura el nombre de la ruta actual
        return this.capitalizeFirstLetter(name); // Llama a tu método de capitalización
      },
    },
    methods : {

      /**
       * On Sidebar Toggle
       * @param collapsed
       */
      onToggleCollapse(collapsed) {
        this.collapsed = collapsed;
      },


      /**
       * On Window Resize
       */
      onResize () {
        if (window.innerWidth <= 767) {
          this.isOnMobile = true;
          this.collapsed = true;
        } else {
          this.isOnMobile = false;
          this.collapsed = false;
        }
      },

      /**
       * Logout
       */
      logout() {
        this.loading = true;
        this.$http.post('/auth/logout', {
          accessToken  : localStorage.accessToken,
          refreshToken : localStorage.refreshToken,
        }).then(() => {
          window.localStorage.clear();
          this.$router.replace('/admin/login');
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },


      capitalizeFirstLetter(string) {
        console.log('[ROUTER]', string);
        return string.charAt(0).toUpperCase() + string.slice(1); // Capitaliza la primera letra
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      collapseMenu() {
        this.collapsed = !this.collapsed;
      }
    },
    mounted() {
      this.initScrollbar();
      this.onResize();
      window.addEventListener('resize', this.onResize);

      this.$store.dispatch('playlist/getRecentSongs');
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/components/admin/main2';
  .navbar {
    z-index: 999;
  }
</style>
