<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <h3>Users</h3>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="pt-2 pb-2 mb-2">
        <FilterBar></FilterBar>
      </div>
      <Vuetable 
        class="bg-white" ref="vuetable"
        @vuetable:pagination-data="onPaginationData"
        @vuetable:loading="showLoader"
        @vuetable:loaded="hideLoader"
        data-path="mydata"
        pagination-path="pagination"
        :api-url="apiUrl"
        :fields="fields"
        :http-options="httpOptions"
        :css="css.table"
        :per-page="10"
        :append-params="moreParams"
        :query-params="{
          sort: 'sort',
          page: 'page',
          perPage: 'perPage'
        }"
      >
        <template slot="status" slot-scope="props">
          <div v-if="props.rowData.is_social == 0">
            <div v-if="props.rowData.status === 1">
              <span v-if="props.rowData.activation === 1"> Activated </span>
              <span v-else> For Activation </span>
            </div>
            <span v-else> Deactivated </span>
          </div>
          <div v-else>
            <span v-if="props.rowData.status == 1"> Activated </span>
            <span v-else> Deactivated </span>
          </div>
        </template>
      </Vuetable>
      <div class="d-flex align-items-center justify-content-end">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
        <vuetable-pagination ref="pagination"
                            @vuetable-pagination:change-page="onChangePage"
                            :css="css.pagination">
        </vuetable-pagination>
      </div>
    </div>
    <v-dialog/>
    <modals-container v-on:modalClose="onModalClose"/>
    <!-- Loading -->
    <loading :active.sync="loading"
             :is-full-page="true"></loading>
  </div>
</template>
<script>
  /* eslint-disable camelcase */
  const Loading = () => import('vue-loading-overlay');
  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');
  const UserListActions = () => import('./UserListActions.vue');
  const FilterBar = () => import('./FilterBar.vue');
  import moment from 'moment-timezone';

  import Vue from 'vue';
  import TableStyle from '../table-bootstrap-css.js';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import baseHeader from '../../BaseHeader';

  Vue.component('user-list-actions', UserListActions);

  export default {
    name : 'UserList',
    data() {
      return {
        loading     : false,
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/users',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name       : 'user_id',
            title      : 'ID',
            sortField  : 'user_id',
            direction  : 'asc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name      : 'nickname',
            title     : 'Nickname',
            sortField : 'nickname',
            direction : 'asc',
          },
          {
            name      : 'firstname',
            title     : 'First Name',
            sortField : 'firstname',
            direction : 'asc',
          },
          {
            name      : 'lastname',
            title     : 'Last Name',
            sortField : 'lastname',
            direction : 'asc',
          }, {
            name      : 'email',
            title     : 'Email',
            sortField : 'email',
            direction : 'asc',
          },
          {
            name       : 'created',
            title      : 'Created',
            sortField  : 'created',
            direction  : 'asc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : 'last_activity_date',
            title      : 'Last Activity Date',
            sortField  : 'last_activity_date',
            direction  : 'asc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : 'platform_signup',
            title      : 'Platform Signup',
            sortField  : 'platform_signup',
            direction  : 'asc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__slot:status',
            title      : 'Status',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__component:user-list-actions',
            title      : 'Actions',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
        ],
      };
    },
    components : {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
      FilterBar,
      Loading,
      baseHeader
    },
    methods : {

      /**
       * Get Status
       * @param value
       * @returns {string}
       */
      convertStatus(value) {
        return (value) ? 'Activated' : 'Deactivated';
      },

      /**
       * Update pagination info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * On Add User
       */
      onAddUser() {
        this.$refs.vuetable.reload();
      },

      /**
       * On Change Page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Filter Set
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
         * On Reset Filter
         */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * On Close Modal
       */
      onModalClose() {
        this.$refs.vuetable.reload();
      },

      onDateSet(date){
        this.moreParams = {
          'startDate' : date.start,
          'endDate'   : date.end,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * For Pagination
       *
       * @param data
       * @return {object}
       */
      transform : function (data) {
        const transformed = {};

        transformed.pagination = {
          total         : data.total,
          per_page      : data.perPage,
          current_page  : data.currentPage,
          last_page     : data.lastPage,
          next_page_url : data.nextPageUrl,
          prev_page_url : data.prevPageUrl,
          from          : data.from,
          to            : data.to,
        };

        transformed.mydata = [];

        for (let i = 0; i < data.data.length; i++) {
          //Date conversion
          //got string representation of date time from database hence
          //created_utc and instantiated as moment object with timezone
          // no conversion done

          let lastActivDate = null;
          const created = moment.tz(data.data[i].created_utc,
                                    'YYYY-MM-DD HH:mm:ss', 'UTC');
          if(data.data[i].last_activity_date){
            const lastDate = moment.tz(data.data[i].last_activity_date,
                                       'YYYY-MM-DD HH:mm:ss', 'UTC');
            lastActivDate = moment(lastDate).local()
              .format('MM/DD/YY');
          }
          
          //converted created moment to timezone
          // const createdConv = moment(created).tz('America/Chicago')
          //   .format('MMM DD, YYYY, hh:mm:ss A z');
          const createdConv = moment(created).local()
            .format('MM/DD/YY');

          transformed.mydata.push({
            user_id            : data.data[i].user_id,
            nickname           : data.data[i].nickname,
            firstname          : data.data[i].firstname,
            lastname           : data.data[i].lastname,
            email              : data.data[i].email,
            status             : data.data[i].status,
            user_type          : data.data[i].user_type,
            premium_type       : data.data[i].premium_type,
            school_id          : data.data[i].school_id,
            school             : data.data[i].school,
            school_full        : data.data[i].school_full,
            grade_level        : data.data[i].grade_level,
            activation         : data.data[i].activation_used,
            is_social          : data.data[i].is_social,
            last_activity_date : lastActivDate,
            platform_signup    : data.data[i].platform_signup,
            created            : createdConv,
          });
        }

        return transformed;
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * hide Loader
       */
      hideLoader() {
        this.loading = false;
      },
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData));
      this.$events.$on('filter-reset', () => this.onFilterReset());
      this.$events.$on('add-user', () => this.onAddUser());
      this.$events.$on('date-clear', () => this.onFilterReset());
      this.$events.$on('date-set', eventData => this.onDateSet(eventData));
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/userlist/user-list";
  .baseHeader, .header {
    margin-top: -80px;
  }

  table {
    z-index: 99;
    position: relative;
  }
</style>