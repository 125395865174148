<template>
  <div>

    <!-- <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success baseHeader">
      <b-row>
        <b-col xl="3" md="6">
          <stats-card title="Total traffic" type="gradient-red" sub-title="350,897" icon="ni ni-active-40" class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">3.48%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total traffic" type="gradient-orange" sub-title="2,356" icon="ni ni-chart-pie-35"
            class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Sales" type="gradient-green" sub-title="924" icon="ni ni-money-coins" class="mb-4">

            <template slot="footer">
              <span class="text-danger mr-2">5.72%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Performance" type="gradient-info" sub-title="49,65%" icon="ni ni-chart-bar-32"
            class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">54.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header> -->

    <template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success baseHeader">
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <stats-card
            title="Total Users"
            type="gradient-red"
            :sub-title="totalUsers ? totalUsers.toLocaleString() : 'Loading...'"
            icon="ni ni-single-02"
            class="mb-4"
          >
            <template slot="footer">
              <span class="text-success mr-2">Updated</span>
              <span class="text-nowrap">{{ lastUpdated }}</span>
            </template>
          </stats-card>
        </b-col>
        <!-- <b-col xl="4" md="6">
          <stats-card
            title="Top Streak Users"
            type="gradient-info"
            icon="ni ni-trophy"
            class="mb-4"
          >
            <template v-if="topStreakUsers.length" slot="sub-title">
              <ul>
                <li v-for="(user, index) in topStreakUsers" :key="index">
                  {{ user.nickname }} - {{ user.current_streak }} days
                </li>
              </ul>
            </template>
            <template v-else slot="sub-title">
              <p>No streak data available.</p>
            </template>
          </stats-card>
        </b-col> -->
        <b-col xl="3" md="6">
  <stats-card
    title="Top Streak Users"
    type="gradient-info"
    icon="ni ni-trophy"
    class="mb-4"
  >
    <!-- <template slot="sub-title"> -->
      <h5 class="card-title text-uppercase text-muted mb-0">Top Streak Users</h5>
      <div v-if="topStreakUsers.length">
        <ul class="list-unstyled mb-0">
          <li
            v-for="(user, index) in topStreakUsers"
            :key="index"
            class="d-flex justify-content-between align-items-center mt-1"
          >
            <span>
              <strong>{{ index + 1 }}.</strong> {{ user.nickname }}
            </span>
            <span class="badge badge-light">
              {{ user.current_streak }} days
            </span>
          </li>
        </ul>
      </div>
      <div v-else>
        <p class="mb-0">No streak data available.</p>
      </div>
    <!-- </template> -->
  </stats-card>
</b-col>
        <b-col xl="3" md="6">
          <stats-card title="Firebase Analytics" type="gradient-orange" :sub-title="false ? 'totalUsers.toLocaleString()' : 'Loading...'" icon="ni ni-chart-pie-35"
            class="mb-4">
            <!-- <template slot="footer">
              <span class="text-success mr-2">12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Cloud Firestore" type="gradient-orange" :sub-title="false ? 'totalUsers.toLocaleString()' : 'Loading...'" icon="ni ni-chart-pie-35"
            class="mb-4">
            <!-- <template slot="footer">
              <span class="text-success mr-2">12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Sales" type="gradient-green" sub-title="924" icon="ni ni-money-coins" class="mb-4">
            <template slot="footer">
              <span class="text-danger mr-2">5.72%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col> -->
        <!-- <b-col xl="3" md="6">
          <stats-card title="Performance" type="gradient-info" sub-title="49,65%" icon="ni ni-chart-bar-32"
            class="mb-4">
            <template slot="footer">
              <span class="text-success mr-2">54.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col> -->
      </b-row>
    </base-header>
  </div>
</template>

    <!--Charts-->
    <b-container fluid class="mt-6">
      <b-row>
        <b-col xl="8" class="mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Sales value</h5>
              </b-col>
              <b-col>
                <b-nav class="nav-pills justify-content-end">
                  <b-nav-item class="mr-2 mr-md-0" :active="bigLineChart.activeIndex === 0" link-classes="py-2 px-3"
                    @click.prevent="initBigChart(0)">
                    <span class="d-none d-md-block">Month</span>
                    <span class="d-md-none">M</span>
                  </b-nav-item>
                  <b-nav-item link-classes="py-2 px-3" :active="bigLineChart.activeIndex === 1"
                    @click.prevent="initBigChart(1)">
                    <span class="d-none d-md-block">Week</span>
                    <span class="d-md-none">W</span>
                  </b-nav-item>
                </b-nav>
              </b-col>
            </b-row>
            <line-chart :height="350" ref="bigChart" :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </card>
        </b-col>

        <b-col xl="4" class="mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total orders</h5>
              </b-col>
            </b-row>

            <bar-chart :height="350" ref="barChart" :chart-data="redBarChart.chartData">
            </bar-chart>
          </card>
        </b-col>
      </b-row>
      <!-- End charts-->

      <!--Tables-->
      <b-row class="mt-5">
        <b-col xl="8" class="mb-5 mb-xl-0">
          <page-visits-table></page-visits-table>
        </b-col>
        <b-col xl="4" class="mb-5 mb-xl-0">
          <social-traffic-table></social-traffic-table>
        </b-col>
      </b-row>
      <!--End tables-->
    </b-container>

  </div>
</template>
<script>
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

// Components
import BaseProgress from '@/components/BaseProgress';
import Card from '../Cards/Card';
import StatsCard from '@/components/Cards/StatsCard';

// Tables
import SocialTrafficTable from './Dashboard/SocialTrafficTable';
import PageVisitsTable from './Dashboard/PageVisitsTable';
import baseHeader from '../BaseHeader';
import axios from 'axios';

export default {
  components: {
    Card,
    baseHeader,
    LineChart,
    BarChart,
    // BaseProgress,
    StatsCard,
    PageVisitsTable,
    SocialTrafficTable
  },
  data() {
    return {
      totalUsers: null, // Métrica de usuarios totales
      lastUpdated: '',
      topStreakUsers: [],
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40]
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: 'Performance',
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            label: 'Sales',
            data: [25, 20, 30, 22, 17, 29]
          }]
        },
        extraOptions: chartConfigs.blueChartOptions
      }
    };
  },
  methods: {
    async fetchTopStreakUsers() {
      try {
        const apiUrl = `${process.env.VUE_APP_ROOT_API}api/top-streak-users`;
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`, // Token de autenticación
          },
        });

        if (response.status === 200 && response.data.success) {
          this.topStreakUsers = response.data.data;
        }
      } catch (error) {
        console.error('Error fetching top streak users:', error);
      }
    },
    async fetchTotalUsers() {
      try {
        const apiUrl = `${process.env.VUE_APP_ROOT_API}api/users`;
        const response = await axios.get(apiUrl, {
          params: {
            page: 1, // Para obtener la métrica total
            perPage: 1, // No necesitamos todos los datos
          },
          headers: {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`, // Token de autenticación
          },
        });

        if (response.status === 200 && response.data.total) {
          this.totalUsers = response.data.total;
          this.lastUpdated = new Date().toLocaleString(); // Fecha y hora actual
        }
      } catch (error) {
        console.error('Error fetching total users:', error);
      }
    },
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: 'Performance',
            data: this.bigLineChart.allData[index]
          }
        ],
        labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    }
  },
  mounted() {
    this.initBigChart(0);
    this.fetchTotalUsers();
    this.fetchTopStreakUsers();
  }
};
</script>
<style>
.el-table .cell {
  padding-left: 0px;
  padding-right: 0px;
}
.baseHeader {
  margin-top: -80px;
}
</style>
