<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <h3>Enterprise Accounts</h3>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="">
        <FilterBar></FilterBar>
      </div>

      <!-- Accounts -->
      <vuetable data-path="accounts"
                pagination-path="pagination"
                ref="vuetable"
                track-by="user_id"
                :api-url="apiUrl"
                :fields="fields"
                :http-options="httpOptions"
                :css="css.table"
                :per-page="10"
                :append-params="moreParams"
                :query-params="{
                  sort    : 'sort',
                  page    : 'page',
                  perPage : 'perPage'
                }"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="showLoader"
                @vuetable:loaded="hideLoader">
      </vuetable>

      <!-- Pagination -->
      <div class="d-flex align-items-center justify-content-end">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
        <vuetable-pagination ref="pagination"
                            @vuetable-pagination:change-page="onChangePage"
                            :css="css.pagination">
        </vuetable-pagination>
      </div>
    </div>
    

    <!-- Modal -->
    <modals-container />

    <!-- Dialog -->
    <v-dialog />

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.3"
             :width="70"
             :height="70"
    ></loading>
  </div>
</template>

<script>
  const EnterpriseListActions = () => import('./EnterpriseListActions.vue');
  const FilterBar = () => import('./FilterBar.vue');
  const Loading = () => import('vue-loading-overlay');
  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination =
    () => import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo =
    () => import('vuetable-2/src/components/VuetablePaginationInfo');

  import Vue from 'vue';
  import moment from 'moment-timezone';
  import TableStyle from '../table-bootstrap-css.js';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import baseHeader from '../../BaseHeader';

  Vue.component('enterprise-list-actions', EnterpriseListActions);

  export default {
    name : 'EnterpriseList',
    data() {
      return {
        isLoading   : false,
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/enterprise/accounts',
        moreParams  : {},
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        css    : TableStyle,
        fields : [ {
          name       : 'user_id',
          title      : 'ID',
          sortField  : 'user_id',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        },  {
          name       : 'email',
          title      : 'Email',
          sortField  : 'email',
          direction  : 'desc',
          titleClass : 'text-center',
        }, {
          name       : 'school_name',
          title      : 'School',
          sortField  : 'school_name',
          direction  : 'desc',
          titleClass : 'text-center',
        }, {
          name       : 'coupon',
          title      : 'Coupon',
          sortField  : 'coupon',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'user_limit',
          title      : 'User Limit',
          sortField  : 'user_limit',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'users',
          title      : 'Subscribers',
          sortField  : 'total_users',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'custom_price',
          title      : 'Price',
          sortField  : 'custom_price',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-right',
        }, {
          name       : 'billing_start',
          title      : 'Billing Start',
          sortField  : 'billing_start',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'billing_end',
          title      : 'Billing End',
          sortField  : 'billing_end',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'status',
          title      : 'Subscription<br>Status',
          sortField  : 'status',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__component:enterprise-list-actions',
          title      : 'Actions',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        } ],
      }
    },
    components : {
      FilterBar,
      Loading,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
      baseHeader
    },
    methods : {

      /**
       * For Pagination
       *
       * @param data
       * @return {object}
       */
      transform(data) {
        data = data.result;
        const transformed = {};

        transformed.pagination = {
          'total'         : data.total,
          'per_page'      : data.perPage,
          'current_page'  : data.currentPage,
          'last_page'     : data.lastPage,
          'next_page_url' : data.nextPageUrl,
          'prev_page_url' : data.prevPageUrl,
          'from'          : data.from,
          'to'            : data.to,
        };

        transformed.accounts = [];

        for (let i = 0; i < data.data.length; i++) {
          // From UTC to Local time
          // const createdConv = moment.utc(data.data[i].created_utc).local()
          //   .format('MMM DD, YYYY, hh:mm:ss A z');
          // const startConv = moment.utc(data.data[i].billing_start).local()
          //   .format('MMM DD, YYYY, hh:mm:ss A z');
          // const endConv = moment.utc(data.data[i].billing_end).local()
          //   .format('MMM DD, YYYY, hh:mm:ss A z');

          const createdConv = moment.utc(data.data[i].created_utc).local()
            .format('MM/DD/YY, hh:mm:ss A z');
          const startConv = moment.utc(data.data[i].billing_start).local()
            .format('MM/DD/YY, hh:mm:ss A z');
          const endConv = moment.utc(data.data[i].billing_end).local()
            .format('MM/DD/YY, hh:mm:ss A z');

          transformed.accounts.push({
            'user_id'         : data.data[i].user_id,
            'email'           : data.data[i].email,
            'status'          : data.data[i].status,
            'user_type'       : data.data[i].user_type,
            'school_id'       : data.data[i].school_id,
            'school'          : data.data[i].school,
            'activation'      : data.data[i].activation_used,
            'is_social'       : data.data[i].is_social,
            'created'         : createdConv,
            'billing_start'   : startConv,
            'billing_end'     : endConv,
            'school_name'     : data.data[i].school_name,
            'custom_price'    : data.data[i].custom_price.toFixed(2),
            'coupon'          : data.data[i].coupon,
            'user_limit'      : data.data[i].user_limit,
            'payment_id'      : data.data[i].payment_id,
            'subscription_id' : data.data[i].subscription_id,
            'users'           : data.data[i].total_users,
          });
        }

        return transformed;
      },

      /**
       * Update Paginaton info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.isLoading = true;
      },

      /**
       * Hide Loader
       */
      hideLoader() {
        this.isLoading = false;
      },

      /**
       * On Change Page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Filter Set
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * On Reset Filter
       */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData));
      this.$events.$on('filter-reset', () => this.onFilterReset());
      this.$events.$on('loading-state',
                       eventData => {
                         if (eventData)
                           this.showLoader();
                         else
                           this.hideLoader();
                       });
      this.$events.$on('reload', () => {
        this.$refs.vuetable.refresh();
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/enterprise/enterprise-list";
  .baseHeader, .header {
    margin-top: -80px;
  }

  table {
    z-index: 99;
    position: relative;
    background: white;
  }
</style>