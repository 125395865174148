<template>
  <div>
    <div fluid>
      <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
        <h3>Upload Video</h3>
      </base-header>
      <div class="container-fluid mt--7">
        <b-row class="bg-white">
          <b-col>
            <div id="wistia_uploader"></div>
          </b-col>
        </b-row>
        <b-row class="bg-white" v-if="uploading">
          <b-col>
            <div class="p-3 edit-form">
              <h4>Edit Media</h4>
              <b-form-group label="Media Title: "
                            label-for="mediaTitle"
                            :label-cols="2">
                <b-form-input id="media_name"
                              placeholder="Enter Media Title"
                              autocomplete="off"
                              @input="$v.mediaTitle.$touch()"
                              v-model.trim="$v.mediaTitle.$model"
                              :state="!$v.mediaTitle.$error">
                </b-form-input>
                <b-form-invalid-feedback id="album_name">
                  <span v-if="!$v.mediaTitle.required">
                    This is a required field.
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Premium Type: "
                            label-for="premiumType"
                            :label-cols="2">
                <toggle-button class="large-font"
                               id="premiumType"
                               v-model="premiumType"
                               :width="130"
                               :height="50"
                               :labels="{
                                 checked   : 'Premium',
                                 unchecked : 'Free',
                               }"/>
              </b-form-group>
  
              <!-- Buttons -->
              <div class="d-flex justify-content-end">
                <b-button variant="outline-success"
                          @click="onSubmit"
                          :disabled="!$v.mediaTitle.required ||
                            isFormEdited === false">
                  Submit
                </b-button>
                <b-button class="ml-1 mr-1"
                          variant="outline-danger"
                          @click="onReset">
                  Done
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Loading -->
      <loading :active.sync="isLoading"
                :is-full-page="true"
                background-color="#000"
                color="#068bcd"
                :opacity="0.3"
                :width="70"
                :height="70">
      </loading>
    </div>
  </div>
</template>
<script>
  const nAxios = () => import('axios');
  const Loading = () => import('vue-loading-overlay');

  import { required } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import baseHeader from '../../BaseHeader';


  export default {
    name : 'MediaUpload',
    data() {
      return {
        premiumType   : false,
        mediaTitle    : null,
        uploading     : false,
        mediaId       : null,
        wistiaId      : null,
        uploadedMedia : {},
        isFormEdited  : false,
        isLoading     : false,
      };
    },
    components : {
      Loading,
      baseHeader
    },
    watch : {
      mediaTitle(value) {
        this.isFormEdited = false;
        if (value !== this.uploadedMedia.name)
          this.isFormEdited = true;
      },
      premiumType(value) {
        this.isFormEdited = false;
        if (value !== false)
          this.isFormEdited = true;
      },
    },
    methods : {

      /**
       * Upload Media
       */
      uploadMedia(media) {
        console.log('2222');
        this.uploadedMedia = media;
        this.mediaTitle = media.name;
        this.wistiaId = media.id;
        this.$http.post('api/media', {
          'mediaTitle'  : media.name,
          'wistiaId'    : media.id,
          'mediaType'   : 'video',
          'premiumType' : 0,
          'mediaLength' : media.duration,
          'thumbnail'   : media.thumbnail.url,
        }).then(res => {
          this.uploading = true;
          this.mediaId = res.data.mediaId;
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Uploaded media successfully.',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Submit Form
       */
      onSubmit() {
        console.log('Click');
        this.isLoading = true;
        if (!this.$v.mediaTitle.$error) {
          this.$http.put('api/media', {
            'mediaId'      : this.mediaId,
            'updateFields' : {
              'media_title'  : this.mediaTitle,
              'premium_type' : (this.premiumType) ? 1 : 0,
            },
          }).then(() => {
            this.wistiaRequest(); // Update wistia

            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success!',
              text  : 'Saved media successfully.',
            });
          }).catch(() => {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          });
        }
      },

      /**
       * Wistia Request
       */
      wistiaRequest() {
        console.log('11111');
        nAxios({
          method : 'PUT',
          url    : 'https://api.wistia.com/v1/medias/' +
            this.wistiaId + '.json',
          params : {
            'name'         : this.mediaTitle,
            'api_password' : '4a90d7e04bb110d32fadb0edff2fc9a264dda516' +
              'c5a4e7b29b8cab3b97175251',
          },
          headers : {
            'Access-Control-Allow-Origin' : '*',
          },
        }).then(() => {
          this.isLoading = false;
          this.onReset();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Successfully Changed in Wistia!',
          });
        }).catch(() => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Reset Form
       */
      onReset() {
        window.wistiaUploader.removePreview();
        this.premiumType = false;
        this.mediaTitle = null;
        this.uploading = false;
        this.mediaId = null;
        this.wistiaId = null;
      },
    },
    mounted() {
      console.log('3333');
      const wistiaScript = document.createElement('script');
      wistiaScript
        .setAttribute('src',
                      'https://fast.wistia.com/assets/external/api.js');
      document.head.appendChild(wistiaScript);
      window._wq = window._wq || [];
      const vm = this;
      // eslint-disable-next-line no-undef
      _wq.push(function (W) {
        setTimeout(function () {
          window.wistiaUploader = new W.Uploader({
            accessToken : process.env.VUE_APP_WISTIA_ACCESS_TOKEN,
            dropIn      : 'wistia_uploader',
            projectId   : '9365980',
          });

          window.wistiaUploader.bind('uploadsuccess', function (file, media) {
            vm.uploadMedia(media);
          });

          // eslint-disable-next-line no-unused-vars
          window.wistiaUploader.bind('uploadstart', function (file) {
          });

        }, 1000);
      });
    },
    validations : {
      mediaTitle : { required },
    },
  };
</script>
<style scoped>
  @import 'https://fast.wistia.com/assets/external/uploader.css';
</style>
<style lang="scss" scoped>
  @import '../../../assets/scss/components/admin/media/media-upload';
  .baseHeader, .header {
    margin-top: -80px;
  }

  table {
    z-index: 99;
    position: relative;
  }
</style>
