<template>
  <div id="video-list">
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <h3>Video List</h3>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="">
        <FilterBar></FilterBar>
      </div>
      <vuetable data-path="mydata"
                ref="vuetable"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="showLoader"
                @vuetable:loaded="hideLoader"
                pagination-path="pagination"
                :api-url="apiUrl"
                :fields="fields"
                :http-options="httpOptions"
                :css="css.table"
                :per-page="4"
                :append-params="moreParams"
                :query-params="{
                  sort: 'sort',
                  page: 'page',
                  perPage: 'perPage'
                }">
        <template slot="images" slot-scope="props">
          <img :src="props.rowData.thumbnail"
              alt="thumbnail"
              width="150"
              height="100"
              @click="itemAction('change-thumbnail',
                props.rowData, props.rowIndex)" />
        </template>
      </vuetable>
      <div class="d-flex align-items-center justify-content-end">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
        <vuetable-pagination ref="pagination"
                            @vuetable-pagination:change-page="onChangePage"
                            :css="css.pagination">
        </vuetable-pagination>
      </div>
    </div>
    <v-dialog/>
    <modals-container v-on:modalClose="onModalClose"/>
    <!-- Loading -->
    <loading :active.sync="loading"
             :is-full-page="true"></loading>
  </div>
</template>
<script>
  /* eslint-disable camelcase */
  const Loading = () => import('vue-loading-overlay');
  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');
  const SimpleUpload = () => import('../SimpleUpload.vue');
  const MediaListActions = () => import('./MediaListActions.vue');
  const MediaCategorySelect = () => import('./MediaCategorySelect.vue');
  const FilterBar = () => import('./FilterBar.vue');
  const MediaPremiumToggle = () => import('./MediaPremiumToggle');

  import Vue from 'vue';
  import TableStyle from '../table-bootstrap-css.js';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapActions } from 'vuex';
  import baseHeader from '../../BaseHeader';


  Vue.component('media-list-actions', MediaListActions);
  Vue.component('media-category-select', MediaCategorySelect);
  Vue.component('media-premium-toggle', MediaPremiumToggle);

  export default {
    name : 'VideoList',
    data() {
      return {
        loading     : false,
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/media/video/all',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name       : 'media_id',
            title      : 'Media<br>ID',
            sortField  : 'media_id',
            direction  : 'desc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__slot:images',
            title      : 'Thumbnail',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name      : 'media_title',
            title     : 'Title',
            sortField : 'media_title',
            direction : 'desc',
          },
          {
            name       : 'media_length',
            title      : 'Length',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__component:media-premium-toggle',
            title      : 'Premium',
            titleClass : 'text-center premium',
            dataClass  : 'text-center',
          },
          {
            name       : '__component:media-category-select',
            title      : 'Category',
            titleClass : 'text-center category',
          },
          {
            name       : '__component:media-list-actions',
            title      : 'Actions',
            titleClass : 'text-center actions',
            dataClass  : 'text-center',
          },
          {
            name    : 'categories',
            title   : 'Categories',
            visible : false,
          },
        ],
      };
    },
    components : {
      FilterBar,
      // eslint-disable-next-line vue/no-unused-components
      SimpleUpload,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
      Loading,
      baseHeader
    },
    methods : {
      ...mapActions({
        'getCategories' : 'categories/getCategories',
      }),

      /**
       * Get Transformed data
       * @param data
       */
      transform : function (data) {
        const transformed = {};
        transformed.pagination = {
          total         : data.total,
          per_page      : data.perPage,
          current_page  : data.currentPage,
          last_page     : data.lastPage,
          next_page_url : data.nextPageUrl,
          prev_page_url : data.prevPageUrl,
          from          : data.from,
          to            : data.to,
        };

        transformed.mydata = [];

        for (let i = 0; i < data.data.length; i++) {
          transformed.mydata.push({
            media_id     : data.data[i].media_id,
            media_title  : data.data[i].media_title,
            thumbnail    : data.data[i].thumbnail,
            media_length : Vue.moment.utc(Vue.moment
              .duration(data.data[i].media_length, 's')
              .asMilliseconds()).format('mm:ss'),
            premium_type     : (data.data[i].premium_type) ? 'YES' : 'NO',
            categories       : data.data[i].categories,
            inter_categories : data.data[i].inter_categories,
            notif_sent       : data.data[i].notif_sent,
            is_free          : data.data[i].is_free,
          });
        }

        return transformed;

      },

      /**
       * Set Pagination Info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Change page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * Show change thumbnail modal
       * @param action
       * @param data
       * @param index
       */
      // eslint-disable-next-line no-unused-vars
      itemAction(action, data, index) {
        if (action === 'change-thumbnail') {
          data.img_url = data.thumbnail;
          data.id = data.media_id;
          data.image_type = 'video';
          this.$modal.show(SimpleUpload, {
            data : data,
          }, {
            height : 'auto',
            width  : '30%',
          }, {
            'reload-table' : (event) => {
              this.$refs.vuetable.refresh();
            },
          }
          );
        }
      },

      /**
       * Close Modal
       */
      onModalClose() {
        this.$refs.vuetable.reload();
      },

      /**
       * Set Filter
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Reset Filter
       */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * hide Loader
       */
      hideLoader() {
        this.loading = false;
      },
    },
    mounted() {
      this.$events.$on('filter-set-category', eventData =>
        this.onFilterSet(eventData));
      this.$events.$on('filter-reset-category', () => this.onFilterReset());
      
      this.$store.dispatch('categories/getInteractCategories');

      if (this.$store.getters['categories/categories'] === null)
        this.getCategories();
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/admin/media/video-list';
  .baseHeader, .header {
    margin-top: -80px;
  }

  table {
    z-index: 99;
    position: relative;
    background: white;
  }
</style>
