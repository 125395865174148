<template>
  <div class="row no-gutters">
    <div class="col-12 col-lg-4">
      <div class="input-group">
        <input class="form-control"
              id="srch-term"
              name="srch-term"
              type="text"
              title="Search User"
              placeholder="Search"
              autocomplete="off"
              v-model="filterText"
              @keyup.enter="doFilter" />
        <div class="input-group-append">
          <button class="btn btn-primary"
                  type="submit"
                  title="Search User"
                  @click="doFilter">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <button class="btn btn-outline-primary ml-1"
                title="Reset Search Filter"
                @click="resetFilter">
          <i class="fas fa-redo"></i>
        </button>

        <!-- <button class="btn btn-outline-success btn-sm ml-2 mr-2"
                type="button"
                title="Add User Account"
                @click="addUser">
          <i class="fas fa-plus"></i> Add User
        </button>
        <button class="btn btn-outline-success btn-sm"
                type="button"
                title="Send Registration Invite"
                @click="sendInvite">
          <i class="fas fa-envelope"></i> Send Invite
        </button> -->
      </div>
    </div>
    <div class="col-12 col-lg-8">
      <div class="input-group ml-2">
        <datepicker
          ref="dPicker"
          placeholder="Select Date"
          :v-model="selectedDate"
          :use-utc="true"
          input-class="form-control"
          @selected="selectDate"
          >
        </datepicker>

        <button class="btn bg-purple text-white ml-1"
                type="button"
                title="Reset Date Filter"
                @click="clearDate">
          <i class="fas fa-redo"></i>
        </button>

        <button class="btn btn-info btn-sm ml-2 mr-2"
                type="button"
                title="Add User Account"
                @click="addUser">
          <i class="fas fa-plus"></i> Add User
        </button>
        <button class="btn btn-info btn-sm"
                type="button"
                title="Send Registration Invite"
                @click="sendInvite">
          <i class="fas fa-envelope"></i> Send Invite
        </button>
      </div>
    </div>
    <!-- <div class="col-12 col-md-12 col-lg-6">
      <button class="btn btn-outline-success btn-sm mr-2"
              type="button"
              title="Add User Account"
              @click="addUser">
        <i class="fas fa-plus"></i> Add User
      </button>
      <button class="btn btn-outline-success btn-sm"
              type="button"
              title="Send Registration Invite"
              @click="sendInvite">
        <i class="fas fa-envelope"></i> Send Invite
      </button>
    </div>
    <div class="col-12 col-md-12 col-lg-6 text-right">
      <div class="filter">
        <div class="input-group">
          <datepicker
            ref="dPicker"
            placeholder="Select Date"
            :v-model="selectedDate"
            :use-utc="true"
            input-class="form-control"
            @selected="selectDate"
            >
          </datepicker>

          <button class="btn btn-outline-success ml-1"
                  type="button"
                  title="Reset Date Filter"
                  @click="clearDate">
            <i class="fas fa-redo"></i>
          </button>
        </div>
        <div class="input-group">
          <input class="form-control"
                id="srch-term"
                name="srch-term"
                type="text"
                title="Search User"
                placeholder="Search"
                autocomplete="off"
                v-model="filterText"
                @keyup.enter="doFilter">
          <div class="input-group-append">
            <button class="btn btn-primary"
                    type="submit"
                    title="Search User"
                    @click="doFilter">
              <i class="fas fa-search"></i>
            </button>
          </div>
          <button class="btn btn-outline-primary ml-1"
                  title="Reset Search Filter"
                  @click="resetFilter">
            <i class="fas fa-redo"></i>
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  import moment from 'moment-timezone';

  const AddUser = () => import('./AddUser.vue');
  const SendInvite = () => import('./SendInvite.vue');
  export default {
    data() {
      return {
        filterText   : '',
        selectedDate : null,
        startDateUTC : null,
        endDateUTC   : null,
      };
    },
    methods : {

      /**
       * Filter Action
       */
      selectDate(date) {
        if (date) {
          
          //javascript date object from datepicker converted to isostring
          // to remove system timezone
          const dateString = date.toISOString();

          //instantiated moment as CDT this will be start time 
          //default timezone that admin will be in is CDT
          // TODO change to user set timezone
          const localDate = moment.tz(dateString, 'YYYY-MM-DD',
                                      'America/Chicago');
          this.selectedDate = localDate;
 
          //converted to UTC as database will be in utc
          const startDate = moment.tz(localDate.tz('UTC')
            .format('YYYY-MM-DD HH:mm:ss'), 'UTC');

          //endDate is startDate added 1 day then subtracted 1 second
          const endDate = moment(startDate).add(1, 'days')

          this.startDateUTC = localDate.tz('UTC').format('YYYY-MM-DD HH:mm:ss');
          this.endDateUTC = endDate.subtract(1, 'seconds')
            .format('YYYY-MM-DD HH:mm:ss');

          this.$events.fire('date-set', {
            start : this.startDateUTC,
            end   : this.endDateUTC,
          });
        } else {
          this.startDateUTC = null;
          this.endDateUTC= null;
        }        
        
      },

      /**
       * Filter Action
       */
      clearDate() {
        this.$refs.dPicker.clearDate()
        this.$events.fire('date-clear');
      },


      /**
       * Filter Action
       */
      doFilter() {
        this.$events.fire('filter-set', this.filterText);
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.filterText = '';  // clear the text in text input
        this.$events.fire('filter-reset');
      },

      /**
       * Add User
       */
      addUser() {
        this.$modal
          .show(AddUser, {},
                { height : 'auto', adaptive : true, clickToClose : false });
      },

      /**
       * Send Invite
       */
      sendInvite() {
        this.$modal.show(SendInvite, {}, { height : 'auto', adaptive : true });
      },
    },
    components : {
      // eslint-disable-next-line vue/no-unused-components
      AddUser,
      // eslint-disable-next-line vue/no-unused-components
      SendInvite,
      Datepicker,
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/userlist/filter-bar";
</style>
