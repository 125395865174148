<template>
  <div class="container_login">
    <!-- Header -->
    <div class="header bg-gradient-success">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <div class="text-center">
                <img
                  class="h3-logo"
                  src="../../../assets/images/Healthy-Hip-Hop-Logo.png"
                  alt="h3-logo"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">

            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <!-- <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <b-form-group>
                    <b-form-input
                      id="username"
                      aria-describedby="username-input"
                      type="text"
                      placeholder="Username/Email"
                      autocomplete="off"
                      v-model.trim="$v.form.username.$model"
                      @input="$v.form.username.$touch()"
                      :state="!$v.form.username.$error">
                    </b-form-input>
                    <b-form-invalid-feedback id="username-input">
                      <span v-if="!$v.form.username.required">
                        Username is required
                      </span>
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group>
                    <b-form-input
                      id="password"
                      aria-describedby="password-input"
                      type="password"
                      placeholder="Password"
                      autocomplete="off"
                      v-model.trim="$v.form.password.$model"
                      @input="$v.form.password.$touch()"
                      :state="!$v.form.password.$error">
                    </b-form-input>
                    <b-form-invalid-feedback id="password-input">
                      <span v-if="!$v.form.password.required">
                        Password is required
                      </span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4" :disabled="$v.form.$invalid">
                      Login
                    </base-button>
                  </div>
                </b-form> -->
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input 
                    alternative
                    class="mb-3"
                    :rules="{required: true}"
                    prepend-icon="ni ni-email-83"
                    id="username"
                    aria-describedby="username-input"
                    type="text"
                    placeholder="Username/Email"
                    autocomplete="off"
                    v-model.trim="$v.form.username.$model"
                    @input="$v.form.username.$touch()"
                    :state="!$v.form.username.$error"
                    
                    >
                  </base-input>

                  <base-input 
                    alternative
                    class="mb-3"
                    name="Password"
                    :rules="{required: true,}"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                    id="password"
                    aria-describedby="password-input"
                    autocomplete="off"
                    v-model.trim="$v.form.password.$model"
                    @input="$v.form.password.$touch()"
                    :state="!$v.form.password.$error"
                  >
                  </base-input>

                  <div class="text-center">
                    <div class="text-center">
                      <base-button type="primary" native-type="submit" class="" :disabled="$v.form.$invalid">
                        Login
                      </base-button>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- Loading -->
    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import BaseButton from '../../BaseButton.vue';

  export default {
    data() {
      return {
        model: {
          email: '',
          password: '',
          rememberMe: false
        },
        form: {
          username: null,
          password: null,
        },
        loading: false
      };
    },
    components : {
      BaseButton,
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    computed: {
      ...mapGetters({
        authStatus: 'auth/authStatus',
      }),
    },
    watch: {
      authStatus(val) {
        if (val)
          this.loading = (val === 'loading') ? true : false;
      },
    },
    methods: {
      /**
       * Admin Login
       */
      onSubmit() {
        this.$store.dispatch('auth/login', {
          email: this.form.username,
          password: this.form.password,
          isAdmin: true,
        })
        .then(response => {
          // Maneja la respuesta exitosa
        })
        .catch(error => {
          // Maneja el error, por ejemplo, credenciales incorrectas
          this.$bvToast.toast('Invalid credentials, please try again.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        });
      },
    },
    validations: {
      form: {
        username: { required },
        password: { required },
      },
    },
  };
</script>

<style scoped>
  .header {
    background: linear-gradient(87deg, #11cdef 0, #1171ca 100%);
    position: relative;
    z-index: 0;
  }

  .header-body {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .header .separator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .separator svg {
    position: absolute;
    bottom: -1px;
    width: 100%;
  }

  .btn-wrapper .btn {
    margin: 0.5rem;
  }

  .btn-neutral {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd;
  }

  .btn-neutral:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .login-form {
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
  }

  .b-form-group {
    margin-bottom: 1.5rem;
  }

  .b-form-input {
    border-radius: 1rem;
    padding: 0.8rem;
  }

  .b-button {
    border-radius: 1.5rem;
    padding: 0.8rem;
    font-weight: bold;
  }

  .header[data-v-2853b60c] {
    background: linear-gradient(87deg, #11cdef 0, #1171ca 100%);
    position: relative;
    z-index: 0;
    height: 50vh;
  }

  .header {
    height: 50vh !important;
  }

.container_login {
  height: 100vh;
  background: #172b4d;
}
</style>