<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <h3>Influencers</h3>
    </base-header>
    <div class="container-fluid mt--7">
      <b-card no-body>
        <b-tabs content-class="mt-2" fill card
                v-model="tabIndex">
          <b-tab :title-link-class="linkClass(0)"
                 title="Influencer List" active>
            <influencer-list />
          </b-tab>
          <b-tab :title-link-class="linkClass(1)"
                 title="Schedule" lazy>
            <schedule />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    
    <!-- Dialog -->
    <v-dialog/>

    <!-- Modal -->
    <modals-container />
  </div>
</template>

<script>
  const InfluencerList = () => import('./InfluencerList.vue');
  const Schedule = () => import('./Schedule.vue');

  import baseHeader from '../../BaseHeader';

  export default {
    name : 'Influencers',
    data() {
      return {
        tabIndex : 0,
      }
    },
    methods : {
      linkClass(idx) {
        if (this.tabIndex === idx)
          return [ 'bg-primary', 'text-light', 'font-weight-bold', 'inf-tab' ];
        
        return [ 'bg-light', 'text-info', 'inf-tab' ];
      },
    },
    components : {
      InfluencerList,
      Schedule,
      baseHeader
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/influencers";
  .baseHeader, .header {
    margin-top: -80px;
  }

  table {
    z-index: 99;
    position: relative;
  }
</style>